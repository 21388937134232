import React, { useState, useEffect } from "react";
import axios from "axios";
import logo from "../assets/images/logo_shbet.png";
import ticket from "../assets/images/ticket.svg";
import "../assets/styles/style.css";
import Modal from "./Modal";
import homeIcon from "../assets/images/home.png";
import homeIconPC from "../assets/images/home-pc.png";



import tablet from "../assets/images/tablet.png";
import headerForm from "../assets/images/header-form.png";

import {
  addPointClient,
  getCaptchaTokenClient,
  getCodeClient,
} from "../services/api.service";
import Swal from "sweetalert2";
import moment from "moment-timezone";

import { information } from "../const/info.const";
const PromoPage = () => {
  const [promoCode, setPromoCode] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [captchaBase64, setCaptchaBase64] = useState("");

  useEffect(() => {
    if (captchaBase64) {
      document.getElementById("captcha-image").src = captchaBase64;
    }
  }, [captchaBase64]);

  const handleCheckCode = async () => {
    if (!promoCode.trim()) {
      setWarningMessage("Mã Code không được bỏ trống");
      return;
    }
    let valueCode = promoCode.trim();
    Swal.fire({
      titleText: "Đang kiểm tra ...",
      color: "#ffffff",
      allowOutsideClick: false,
      background: `#62ABC3`,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      let loadCaptcha = await getCaptchaTokenClient();
      setCaptchaBase64(loadCaptcha.captchaUrl);
      document.cookie = `token=${loadCaptcha.token}; max-age=180; path=/`;
      let tokenCaptcha = loadCaptcha.token;
      Swal.fire({
        title: "Mã Xác Thực",
        showConfirmButton: false,
        allowOutsideClick: false,
        html: `
        <div class="input-form-captcha-wrapper">
          <div class="thumb-captcha">
            <img id="captcha-image" class="image-captcha notranslate" src="" alt="Captcha Image">
          </div>
          <div class="input-form-captcha">
            <input class="notranslate" translate="no" type="text" placeholder="Nhập mã captcha" id="captcha-input">
            </input>
          </div>
        </div>
        <button id="verify-captcha" class="swal2-confirm swal2-styled">Xác Thực</button>
      `,
      });

      document.getElementById("captcha-image").src = loadCaptcha.captchaUrl;
      document
        .getElementById("captcha-image")
        .addEventListener("click", async () => {
          let newCaptcha = await getCaptchaTokenClient();
          setCaptchaBase64(newCaptcha.captchaUrl);
          tokenCaptcha = newCaptcha.token;
          document.cookie = `token=${newCaptcha.token}; max-age=180; path=/`;
          document.getElementById("captcha-image").src = newCaptcha.captchaUrl;
        });

      document
        .getElementById("verify-captcha")
        .addEventListener("click", async () => {
          const captchaInputValue =
            document.getElementById("captcha-input").value;
          console.log("clientTokenclientTokenclientToken", tokenCaptcha);
          let codeResult = await getCodeClient(
            valueCode,
            captchaInputValue,
            tokenCaptcha
          );
          if (codeResult.status_code === 403) {
            Swal.fire({
              icon: "error",
              allowOutsideClick: false,
              title: codeResult.title_mess,
              background: "none",
              html:
                "<div class='modal-text-wrapper'>" +
                "<div class='modal-text-cont'>" +
                "<p class='modal-text'>Mã Code: &nbsp;</p>" +
                "<p style='font-weight: 600'>" +
                codeResult.detail.promo_code +
                "</p>" +
                "</div>" +
                "<div class='modal-text-cont'>" +
                "<p class='modal-text'>Điểm thưởng: &nbsp;</p>" +
                "<p style='font-weight: bold; color:#ec164c'>" +
                codeResult.detail.point +
                "&nbsp; điểm</p>" +
                "</div>" +
                "</div>",
              footer:
                '<a class="cskh" href="' +
                information?.cskh_url +
                '" target="_blank">Chăm sóc khách hàng 24/7</a>',
            }).then(() => {
              window.location.reload();
            });
          } else if (codeResult.status_code === 400) {
            Swal.fire({
              icon: "error",
              title: "Lỗi xác thực",
              text: "Mã captcha không đúng. Vui lòng thao tác lại.",
            });
            return;
          } else {
            if (codeResult.valid === false) {
              Swal.fire({
                icon: "warning",
                title: codeResult.title_mess,
                text: codeResult.text_mess,
                confirmButtonText: "Thử lại",
                footer:
                  '<a href="' +
                  information?.cskh_url +
                  '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
              }).then(() => {
                window.location.reload();
              });
            } else if (codeResult.valid === true) {
              let time = moment(codeResult.detail.time)
                .tz("Asia/Ho_Chi_Minh")
                .format("DD/MM/YYYY, H:mm:ss");
              const { value: input_data } = await Swal.fire({
                allowOutsideClick: false,
                html: `
                <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 8px;">
                  <div class="outer-circle">
                    <div class="inner-circle">
                      <div class="checkmark"></div>
                    </div>
                  </div>
                </div>
                <h2>${codeResult.title_mess}</h2>
                <table style="width: 100%; text-align: left;">
                  <tbody>
                    <tr>
                      <td>Mã Code:</td>
                      <td class="align-right"><strong>${codeResult?.detail?.promo_code}</strong></td>
                    </tr>
                    <tr>
                      <td>Điểm thưởng:</td>
                      <td class="pointbonus align-right"><strong>${codeResult?.detail?.point} điểm </strong></td>
                    </tr>
                    <tr>
                      <td>${codeResult?.detail?.mess}:</td>
                      <td class="align-right">${time}</td>
                    </tr>
                  </tbody>
                </table>
              `,
                footer:
                  '<a href="' +
                  information?.cskh_url +
                  '" target="_blank">Chăm sóc khách hàng 24/7</a>',
                input: "text",
                inputLabel: "Vui lòng nhập tên tài khoản để nhận thưởng",
                inputPlaceholder: "Tên tài khoản",
                showCancelButton: true,
                cancelButtonText: "Hủy",
                confirmButtonText: "Xác nhận",
                inputValidator: async (value) => {
                  if (!value) {
                    return "Vui lòng nhập tên tài khoản!";
                  }
                },
              });
              if (input_data) {
                Swal.fire({
                  text: "Đang cộng điểm ...",
                  allowOutsideClick: false,
                  width: 300,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
                let addPoint = await addPointClient(
                  input_data,
                  codeResult.detail.promo_code
                );
                if (addPoint.status_code === 502) {
                  Swal.fire({
                    icon: "warning",
                    title: addPoint.title_mess,
                    text: addPoint.text_mess,
                    footer:
                      '<a href="' +
                      information?.cskh_url +
                      '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
                  }).then(() => {
                    window.location.reload();
                  });
                } else {
                  if (addPoint.valid === false) {
                    Swal.fire({
                      icon: "error",
                      title: addPoint.title_mess,
                      text: addPoint.text_mess,
                      footer:
                        '<a href="' +
                        information?.cskh_url +
                        '" target="_blank">Chăm sóc khách hàng 24/7.</a>',
                    }).then(() => {
                      window.location.reload();
                    });
                  } else if (addPoint.valid === true) {
                    await Swal.fire({
                      html: `
                      <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 8px;">
                        <div class="outer-circle">
                          <div class="inner-circle">
                            <div class="checkmark"></div>
                          </div>
                        </div>
                      </div>
                      <h2>Chúc mừng</h2>
                      <p>
                        Chúc mừng <strong class="pointbonus">${addPoint?.player_id}</strong> đã nhận thành công 
                        <span style="color: red; font-weight: bold;">${addPoint?.point} điểm</span>
                      </p>
                      <div class="pyro" id="pyro">
                        <div class="before"></div>
                        <div class="after"></div>
                        <div class="before"></div>
                        <div class="after"></div>
                        <div class="before"></div>
                        <div class="after"></div>
                      </div>
                  `,
                      showCancelButton: false,
                      confirmButtonText: "TIẾP TỤC",
                      customClass: {
                        confirmButton: "popup-button confirm",
                      },
                      allowOutsideClick: false,
                    }).then(() => {
                      window.location.reload();
                    });
                  }
                }
              }
            }
          }
        });
    } catch (error) {
      console.error("Error fetching code:", error);
    }
    //  finally {
    //   Swal.close(); // Ensure Swal is closed after the operation
    // }
  };

  const handleLinkClick = () => {
    setIsModalOpen(true);
  };

  const handleHomeClick = () => {
    window.location.href = information?.cskh_home;
  };

  return (
    <div className="body">
      <div className="modal-custom"></div>
      <div
        className="promo-page"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          display: "flex",
          width: "100%",
          height: "100%",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span onClick={handleHomeClick} className="home-button">
          {/* <span className="home-text">Trang chủ</span> */}
          <img src={homeIconPC} alt="Home Icon" className="home-icon-pc" />
          <img src={homeIcon} alt="Home Icon" className="home-icon" />
        </span>
        <div className="body-content">
          <div className="thumb-logo">
            <img src={logo} alt="SHBET" className="promo-logo" />
          </div>
          <div className="promo-form">
            <div className="thumb-header-form">
              <img src={headerForm} alt="Nhập code nhận thưởng" className="image-header"></img>
            </div>
            {/* <h1 className="promo-title">NHẬP CODE NHẬN THƯỞNG</h1> */}
            <div className="input-container">
              <label className="promo-label" htmlFor="promo-code">
                <img src={ticket} alt="Ticket Icon" className="coupon-icon" />
                <span className="label-text">Mã Code</span>
              </label>
              <input
                id="promo-code"
                type="text"
                placeholder="Nhập mã code"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                className="promo-input"
              />
              <button onClick={handleCheckCode} className="promo-button">
                <span className="text-promo-button">KIỂM TRA NGAY</span>
              </button>
              {warningMessage && (
                <p className="warning-message">{warningMessage}</p>
              )}
              <div className="promo-links">
                <p
                  className="promo-link promo-link-a"
                  onClick={() => handleLinkClick()}
                >
                  CHI TIẾT CHƯƠNG TRÌNH{" "}
                  <span className="arrow">
                    <svg
                      width="37"
                      height="37"
                      viewBox="0 0 37 37"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.5216 0.116211C10.1336 0.116211 2.65026 6.14523 0.895603 14.3551C0.0189633 18.4572 0.609513 22.8193 2.56531 26.5311C4.44762 30.1033 7.53354 32.9904 11.2256 34.6266C15.066 36.3289 19.4749 36.6256 23.5114 35.4647C27.4056 34.3451 30.8584 31.8878 33.2091 28.5893C38.1241 21.6933 37.467 12.0066 31.6845 5.82746C28.2955 2.20609 23.482 0.116211 18.5216 0.116211ZM27.1682 19.1033L22.2546 24.1354C21.0106 25.4097 19.0442 23.4792 20.2832 22.2108L22.7625 19.6718H11.013C10.1786 19.6718 9.48245 18.9752 9.48245 18.1413C9.48245 17.3073 10.1791 16.6107 11.013 16.6107H22.7037L20.1752 14.0827C18.9188 12.8263 20.8668 10.8779 22.1232 12.1343L27.1567 17.1673C27.6903 17.7004 27.6953 18.5638 27.1682 19.1033Z"
                        fill="#FFE788"
                      />
                    </svg>
                  </span>{" "}
                </p>
                <p className="promo-link">
                  <a
                    className="promo-link-a"
                    style={{ padding: 0 }}
                    target="_blank"
                    href="https://t.me/SHBETcodefree88"
                    rel="noreferrer"
                  >
                    TRANG PHÁT CODE{" "}
                    <span className="arrow">
                      <svg
                        width="37"
                        height="37"
                        viewBox="0 0 37 37"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5216 0.116211C10.1336 0.116211 2.65026 6.14523 0.895603 14.3551C0.0189633 18.4572 0.609513 22.8193 2.56531 26.5311C4.44762 30.1033 7.53354 32.9904 11.2256 34.6266C15.066 36.3289 19.4749 36.6256 23.5114 35.4647C27.4056 34.3451 30.8584 31.8878 33.2091 28.5893C38.1241 21.6933 37.467 12.0066 31.6845 5.82746C28.2955 2.20609 23.482 0.116211 18.5216 0.116211ZM27.1682 19.1033L22.2546 24.1354C21.0106 25.4097 19.0442 23.4792 20.2832 22.2108L22.7625 19.6718H11.013C10.1786 19.6718 9.48245 18.9752 9.48245 18.1413C9.48245 17.3073 10.1791 16.6107 11.013 16.6107H22.7037L20.1752 14.0827C18.9188 12.8263 20.8668 10.8779 22.1232 12.1343L27.1567 17.1673C27.6903 17.7004 27.6953 18.5638 27.1682 19.1033Z"
                          fill="#FFE788"
                        />
                      </svg>
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img src={tablet} alt="Tablet" className="tablet-image" />
        {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
      </div>
    </div>
  );
};

export default PromoPage;
